import { NextRouter } from "next/router";

import { DynamicParams } from "@constants";

/**
 * Constructs the dynamic parameters part of the search query
 * @param dynamicParams An object containing key-value pairs of dynamic parameters
 * @returns The dynamic parameters part of the search query
 */
export const buildDynamicParameters = (
  dynamicParams: DynamicParams
): string => {
  const paramsString = Object.entries(dynamicParams)
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join("&");

  return paramsString;
};

/**
 * This function updates the URL with the provided dynamic parameters and additional search query
 * @param dynamicParams An object containing key-value pairs of dynamic parameters
 * @param searchQueryString Additional search query string to be added
 * @param router Next router
 * @returns void
 */
export const pushToHistory = (
  dynamicParams: DynamicParams,
  searchQueryString: string,
  router: NextRouter
) => {
  const { asPath, push } = router;

  const cleanPath = asPath.split("?")[0];

  // Build the dynamic parameters
  const dynamicParamsString = buildDynamicParameters(dynamicParams);

  push(
    {
      search: `${dynamicParamsString}${
        searchQueryString ? `&${searchQueryString}` : ""
      }`,
    },
    `${cleanPath}${searchQueryString ? `?${searchQueryString}` : ""}`,
    { shallow: true }
  );
};
