import { Chip, Flex, Item, Typography } from "@nestoca/ui";
import { MinimalComponentsRecord } from "@shared/constants/mortgages-servicing";
import { useTranslation } from "next-i18next";

import { getBorrowerName } from "@utils";

import styles from "./mortgages-search-item.module.scss";

export type MortgagesSearchItemOption = Pick<
  MinimalComponentsRecord,
  "mainBorrowerId" | "borrowers" | "status"
>;

export const MortgagesSearchItem = (item: Item<MortgagesSearchItemOption>) => {
  const { t } = useTranslation("common");

  const { id: referenceNumber, option } = item;

  // Can't be undefined since the option is set in mortgages-search.tsx
  const { mainBorrowerId, borrowers, status } =
    option as MortgagesSearchItemOption;

  const mainBorrower = borrowers.find(({ id }) => id === mainBorrowerId);

  const coBorrowersName = borrowers
    .filter(({ id }) => id !== mainBorrowerId)
    .map((borrower) => getBorrowerName(t, borrower));

  return (
    <Flex
      className={styles["mortgages-search-item"]}
      direction="column"
      grow={1}
      gap={3}
    >
      <Typography className={styles["main-borrower-name"]} size={0} weight={7}>
        {getBorrowerName(t, mainBorrower)}
      </Typography>
      <Flex align="center" justify="between" gap={3} wrap="wrap">
        <Typography
          className={styles["reference-number"]}
          weight={5}
        >{`#${referenceNumber}`}</Typography>
        <Chip label={t(`loan.status.${status}`, { ns: "mortgages" })} />
      </Flex>
      {!!coBorrowersName.length && (
        <Flex direction="column" gap={1}>
          <Typography size="00" weight={5}>
            {coBorrowersName.join(", ")}
          </Typography>
          <Typography
            size="00"
            weight={5}
            textColor="var(--color-foreground-muted)"
          >
            {t("coApplicant")}
          </Typography>
        </Flex>
      )}
    </Flex>
  );
};
