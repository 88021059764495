import { useMemo } from "react";

import { useRouter } from "next/router";

import { TransactionsParsedQueryParams } from "@constants";
import { extractDateRangeFromParams, extractDateFromParams } from "@utils";

/**
 * This hook is used to get the transactions parsed query params
 * @returns The transactions parsed query params
 */
export const useGetTransactionsParsedQueryParams =
  (): TransactionsParsedQueryParams => {
    const { query } = useRouter();

    const getTransactionsParsedQueryParams = () => {
      const {
        dateRange: dateRangeParams,
        dateStart: dateStartParams,
        dateEnd: dateEndParams,
      } = query;

      const dateRange = extractDateRangeFromParams(dateRangeParams);

      const dateStart = extractDateFromParams(dateStartParams);

      const dateEnd = extractDateFromParams(dateEndParams);

      return {
        dateRange,
        dateStart,
        dateEnd,
      };
    };

    const parsedQueryParams = useMemo(getTransactionsParsedQueryParams, [
      query,
    ]);

    return parsedQueryParams;
  };
