import { useMemo } from "react";

import { useRouter } from "next/router";

/**
 * This hook is used to get the free search query params from the URL
 * @returns The free search query params from the URL
 */
export const useGetFreeSearchParsedQueryParams = () => {
  const { query } = useRouter();

  const getParsedQueryParams = () => {
    const { freeSearch } = query;

    // Free search params can only be a string
    if (Array.isArray(freeSearch)) return "";

    return freeSearch || "";
  };

  const parsedQueryParams = useMemo(getParsedQueryParams, [query]);

  return parsedQueryParams;
};
