import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { TableTab } from "@components/table-tabs/types";
import {
  TableTabUrlSearchParams,
  SetUrlSearchParamsByTableTab,
} from "@constants";

export type TablesProviderProps = {
  children: React.ReactNode;
  tableTabs: TableTab[];
};

export type Context = {
  tableTabs: TableTab[];
  tableTabUrlSearchParams: TableTabUrlSearchParams;
  onSetUrlSearchParamsByTableTab: SetUrlSearchParamsByTableTab;
};

export const TablesContext = createContext<Context | null>(null);

export const TablesProvider = ({
  children,
  tableTabs,
}: TablesProviderProps) => {
  const [tableTabUrlSearchParams, setTableTabUrlSearchParams] =
    useState<TableTabUrlSearchParams>({});

  const initializeTableTabUrlSearchParams = useCallback(() => {
    const tabUrlSearchParams: TableTabUrlSearchParams = {};

    tableTabs?.forEach(({ id }) => {
      tabUrlSearchParams[id] = null;
    });

    setTableTabUrlSearchParams(tabUrlSearchParams);
  }, [tableTabs]);

  const onSetUrlSearchParamsByTableTab = useCallback(
    (tableTabId: string, urlSearchParams: URLSearchParams) => {
      setTableTabUrlSearchParams((currentState) => ({
        ...currentState,
        [tableTabId]: urlSearchParams,
      }));
    },
    []
  );

  const value = useMemo(
    () => ({
      tableTabs,
      tableTabUrlSearchParams,
      onSetUrlSearchParamsByTableTab,
    }),
    [tableTabs, tableTabUrlSearchParams, onSetUrlSearchParamsByTableTab]
  );

  useEffect(() => {
    initializeTableTabUrlSearchParams();
  }, [initializeTableTabUrlSearchParams]);

  return (
    <TablesContext.Provider value={value}>{children}</TablesContext.Provider>
  );
};
