import { useGate } from "@nestoca/gate-react";
import { Flex, FlexProps, useBreakpointValue } from "@nestoca/ui";
import clsx from "clsx";

import styles from "./clipped-content.module.scss";

export const ClippedContent = ({
  children,
  className,
  as,
  ...rest
}: FlexProps) => {
  const isMobile = useBreakpointValue({ default: true, md: false });

  const gate = useGate();
  const isExternalBroker = gate.getRole() === "externalbroker";

  return (
    <Flex
      className={clsx(styles["clipped-content"], className, {
        [styles["clipped-content--mobile"]]: isMobile && isExternalBroker,
      })}
      as={as}
    >
      <Flex className={styles.content} {...rest}>
        {children}
      </Flex>
    </Flex>
  );
};
