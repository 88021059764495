/**
 * This function is used to set the free search to the URL search params
 * @param searchParams URL search params
 * @param freeSearch Free search
 * @returns void
 */
export const setFreeSearchToURLSearchParams = (
  searchParams: URLSearchParams,
  freeSearch: string
) => {
  searchParams.set("freeSearch", freeSearch);
};

/**
 * This function is used to clear the free search from the URL search params
 * @param searchParams URL search params
 * @returns void
 */
export const clearFreeSearchFromURLSearchParams = (
  searchParams: URLSearchParams
) => {
  searchParams.delete("freeSearch");
};
