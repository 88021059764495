import { DocumentTypeSelection } from "@components/statements-filters/types";
import { Params } from "@constants";

/**
 * This function is used to extract the document types from the URL
 * @param documentTypesParams Document types from the URL
 * @returns The document types
 */
export const extractDocumentTypesFromParams = (documentTypesParams: Params) => {
  // Check if the document types params is not an empty string
  if (!documentTypesParams) return undefined;

  // Document types are a comma separated string
  if (typeof documentTypesParams === "string") {
    return documentTypesParams.split(",");
  }

  return undefined;
};

/**
 * This function is used to set the document types to the URL search params
 * @param searchParams URL search params
 * @param documentTypeSelection Document type selection
 * @returns void
 */
export const setDocumentTypesToURLSearchParams = (
  searchParams: URLSearchParams,
  documentTypeSelection: DocumentTypeSelection
) => {
  const documentTypesSelected: string[] = [];

  // Iterate over the document type selection and add the selected document types to the array
  Object.entries(documentTypeSelection).forEach(([key, value]) => {
    if (value) {
      documentTypesSelected.push(key);
    }
  });

  // If there are no document types selected, remove the document types from the URL
  if (documentTypesSelected.length === 0) {
    searchParams.delete("documentTypes");
    return;
  }

  searchParams.set("documentTypes", documentTypesSelected.join(","));
};
