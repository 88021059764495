import { useContext } from "react";

import { TablesContext } from "@provider";

/**
 * This hook is used to access the context of the TablesProvider
 * @returns The context of the TablesProvider
 */
export const useTablesContext = () => {
  const tablesContext = useContext(TablesContext);

  if (!tablesContext) {
    throw new Error("useTablesContext must be used within a TablesProvider");
  }
  return tablesContext;
};
