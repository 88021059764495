import { useCallback, useMemo } from "react";

import {
  debounce,
  Item,
  PAGE_SIZES,
  Search,
  Typography,
  AutoCompletePaginationOptions,
} from "@nestoca/ui";
import { useGetPaginatedMinimalComponentsRecordsSearchResults } from "@shared/api/hooks/mortgages-servicing";
import { mortgagesServicingRouterSchema } from "@shared/constants/mortgages-servicing";
import { useTypedRouter } from "@shared/utils";
import { UseComboboxStateChange, useCombobox } from "downshift";
import { useTranslation } from "next-i18next";
import { toast } from "react-toastify";

import { useGetFreeSearchParsedQueryParams } from "@hooks";
import {
  clearFreeSearchFromURLSearchParams,
  pushToHistory,
  setFreeSearchToURLSearchParams,
} from "@utils";

import {
  MortgagesSearchItem,
  MortgagesSearchItemOption,
} from "./mortgages-search-item";

export type MortgagesSearchProps = {
  urlSearchParams: URLSearchParams;
};

export const MortgagesSearch = ({ urlSearchParams }: MortgagesSearchProps) => {
  const { t } = useTranslation("common");

  const router = useTypedRouter(mortgagesServicingRouterSchema);

  const dynamicParams = useMemo(
    () => ({ tenant: router.query.tenant }),
    [router.query.tenant]
  );

  const MIN_CHARACTERS_REQUIRED = 2;

  const freeSearchParams = useGetFreeSearchParsedQueryParams();

  const {
    data,
    hasNextPage,
    isFetchingNextPage,
    isError,
    isFetching,
    fetchNextPage,
  } = useGetPaginatedMinimalComponentsRecordsSearchResults(
    { freeSearch: freeSearchParams, limit: PAGE_SIZES[0] },
    { enabled: !!freeSearchParams }
  );

  const paginationOptions: AutoCompletePaginationOptions = {
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  };

  const results = useMemo(
    () => data?.pages?.flatMap((page) => page.results) || [],
    [data]
  );

  const items: Item<MortgagesSearchItemOption>[] = useMemo(
    () =>
      results.map(({ mainBorrowerId, borrowers, referenceNumber, status }) => ({
        id: referenceNumber,
        value: "",
        option: { mainBorrowerId, borrowers, status },
      })),
    [results]
  );

  const debounceFreeSearch = useCallback(
    (term: string) =>
      debounce(() => {
        setFreeSearchToURLSearchParams(urlSearchParams, term);

        pushToHistory(dynamicParams, urlSearchParams.toString(), router);
      }, 500)(),
    [dynamicParams, urlSearchParams, router]
  );

  // When the user selects an item from the dropdown
  const onChange = (item: Item<MortgagesSearchItemOption>) => {
    router.push(`/servicing/${item.id}/loan-details`);
  };

  const onInputValueChange = useCallback(
    async ({
      type,
      inputValue,
    }: UseComboboxStateChange<Item<MortgagesSearchItemOption>>) => {
      const term = inputValue || "";

      const inputChange =
        type === useCombobox.stateChangeTypes.InputChange &&
        term.length >= MIN_CHARACTERS_REQUIRED;

      if (inputChange) {
        debounceFreeSearch(term);
      }
    },
    [debounceFreeSearch]
  );

  const onInputClear = () => {
    clearFreeSearchFromURLSearchParams(urlSearchParams);

    pushToHistory(dynamicParams, urlSearchParams.toString(), router);
  };

  if (isError) {
    toast(
      <Typography size={0}>{t("search.error", { ns: "error" })}</Typography>,
      {
        type: "error",
        toastId: "mortgages-search-error",
      }
    );
  }

  return (
    <Search
      paginationOptions={paginationOptions}
      loading={isFetching}
      loadingText={t("loading")}
      placeholder={t("search.placeholder")}
      noResultsText={t("noResultsFound")}
      minCharactersRequiredText={t("search.minCharactersRequired")}
      defaultInputValue={freeSearchParams}
      minimumQueryLength={MIN_CHARACTERS_REQUIRED}
      items={items}
      renderItem={MortgagesSearchItem}
      onChange={onChange}
      onInputClear={onInputClear}
      onInputValueChange={onInputValueChange}
    />
  );
};
