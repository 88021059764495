import { ButtonIcon, Flex, Tooltip, Typography } from "@nestoca/ui";
import { minimalComponentsKeys } from "@shared/api/hooks/mortgages-servicing";
import { ClippedContent } from "@shared/ui/components/clipped-content";
import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "next-i18next";
import { SlRefresh } from "react-icons/sl";

import { MortgagesSearch } from "@components/mortgages-search";

import styles from "./mortgages-header.module.scss";

export type MortgagesHeaderProps = {
  urlSearchParams: URLSearchParams;
};

export const MortgagesHeader = ({ urlSearchParams }: MortgagesHeaderProps) => {
  const { t } = useTranslation("mortgages");

  const queryClient = useQueryClient();

  const REFRESH_TOOLTIP_ID = "refresh-tooltip";

  const onRefreshMortgages = () => {
    queryClient.invalidateQueries({ queryKey: minimalComponentsKeys.list() });
  };

  return (
    <ClippedContent
      className={styles["mortgages-header"]}
      align="center"
      justify="between"
      gap={5}
      as="header"
    >
      <Typography size={3} weight={7}>
        {t("mortgages.header.title")}
      </Typography>
      <Flex align="center" gap={4}>
        <MortgagesSearch urlSearchParams={urlSearchParams} />
        <ButtonIcon
          isRound
          data-tooltip-id={REFRESH_TOOLTIP_ID}
          data-dd-action-name="refresh"
          aria-label="refresh"
          size="medium"
          variant="ghost"
          icon={
            <SlRefresh
              key="refresh-icon"
              size={20}
              color="var(--color-midnight)"
            />
          }
          onClick={onRefreshMortgages}
        />
        <Tooltip
          id={REFRESH_TOOLTIP_ID}
          direction="left"
          title={t("refresh", { ns: "common" })}
        />
      </Flex>
    </ClippedContent>
  );
};
