import { ComponentBorrower } from "@shared/constants/mortgages-servicing";
import { TFunction } from "i18next";

/**
 * This function returns the borrower full name
 * @param borrower The borrower information
 * @returns The borrower full name
 */
export const getBorrowerName = (
  t: TFunction<"common">,
  borrower: ComponentBorrower | undefined
) => {
  if (!borrower) return t("name.unknown");

  const { firstName, lastName } = borrower;

  return `${firstName || ""} ${lastName || ""}`.trim();
};
