export const COMPONENT_ACTIVE_STATUSES = ["CREATED", "ACTIVATED"] as const;

export const COMPONENT_CLOSED_STATUSES = ["CLOSED"] as const;

export const COMPONENT_STATUSES = [
  ...COMPONENT_ACTIVE_STATUSES,
  ...COMPONENT_CLOSED_STATUSES,
] as const;

export const COMPONENT_TYPES = ["MORTGAGE_STANDARD", "HELOC"] as const;

export const COMPONENT_RENEWS_IN = [
  "",
  "30days",
  "60days",
  "90days",
  "120days",
  "150days",
] as const;

export const COMPONENT_PAYMENT_FREQUENCIES = [
  "ANNUALLY",
  "SEMIANNUALLY",
  "QUARTERLY",
  "MONTHLY",
  "SEMIMONTHLY",
  "BIWEEKLY",
  "ACCELERATED_BIWEEKLY",
  "WEEKLY",
  "ACCELERATED_WEEKLY",
  "DAILY",
] as const;

export const COMPONENT_DIGITAL_STATUSES = ["DIGITAL", "NON_DIGITAL"] as const;

export const COMPONENT_PRODUCT_TERMS = [
  "1_YEAR",
  "2_YEAR",
  "3_YEAR",
  "5_YEAR",
  "7_YEAR",
  "10_YEAR",
] as const;

export const COMPONENT_PRODUCT_TYPES = ["VARIABLE", "FIXED"] as const;

export const COMPONENT_SERVICES = ["NESTO", "IG"] as const;

export const COMPONENT_ASSET_TYPES = ["PROPERTY"] as const;

export const COMPONENT_TRANSACTION_TYPES = ["NEW"] as const;
