export const DATE_RANGES = [
  "allTime",
  "yearToDate",
  "lastYear",
  "custom",
] as const;

export type DateOptions = {
  dateRange: string;
  dateStart: string;
  dateEnd: string;
};
