import { useCallback, useMemo } from "react";

import { PAGE_SIZES as TABLE_PAGE_SIZES } from "@nestoca/ui";
import {
  functionalUpdate,
  ColumnFiltersState,
  PaginationState,
  SortingState,
  Updater,
} from "@tanstack/react-table";
import { useRouter } from "next/router";

import { DynamicParams, TableState, TableStateHandlers } from "@constants";
import {
  extractColumnFiltersStateFromParams,
  extractSortingStateFromParams,
  pushToHistory,
  setColumnFiltersToURLSearchParams,
  setPaginationToURLSearchParams,
  setSortingToURLSearchParams,
} from "@utils";

/**
 * This hook is used to get the table state from the URL
 * @returns Table state
 */
export const useGetTableParsedQueryParams = (): TableState => {
  const { query } = useRouter();

  const getParsedQueryParams = () => {
    const {
      sorting: sortingParams,
      columnFilters: columnFiltersParams,
      pageIndex: pageIndexParams,
      pageSize: pageSizeParams,
    } = query;

    const sorting = extractSortingStateFromParams(sortingParams);

    const columnFilters =
      extractColumnFiltersStateFromParams(columnFiltersParams);

    const pageIndex = parseInt(pageIndexParams as string, 10) || 0;

    const parsedPageSize =
      parseInt(pageSizeParams as string, 10) || TABLE_PAGE_SIZES[0];

    const pageSize = TABLE_PAGE_SIZES.includes(parsedPageSize)
      ? parsedPageSize
      : TABLE_PAGE_SIZES[0];

    return {
      sorting,
      columnFilters,
      pagination: { pageIndex, pageSize },
    };
  };

  const parsedQueryParams = useMemo(getParsedQueryParams, [query]);

  return parsedQueryParams;
};

/**
 * This hook is used to get the table state handlers
 * @param urlSearchParams Url search params
 * @param onSetUrlSearchParamsByTableTab Callback to set the url search params by table tab
 * @returns Table state handlers
 */
export const useTableStateHandlers = (
  urlSearchParams: URLSearchParams,
  onSetUrlSearchParamsByTableTab?: () => void,
  dynamicParams?: DynamicParams
): TableStateHandlers => {
  const router = useRouter();

  const {
    columnFilters: columnFiltersParams,
    pagination: paginationParams,
    sorting: sortingParams,
  } = useGetTableParsedQueryParams();

  const memoizedDynamicParams = useMemo(
    () => dynamicParams || { tenant: router.query.tenant as string },
    [dynamicParams, router.query.tenant]
  );

  const onSortingChange = useCallback(
    (updater: Updater<SortingState>) => {
      const updatedSorting = functionalUpdate(updater, sortingParams);

      setSortingToURLSearchParams(urlSearchParams, updatedSorting);

      pushToHistory(memoizedDynamicParams, urlSearchParams.toString(), router);

      // Update the table tab url search params in the provider
      onSetUrlSearchParamsByTableTab?.();
    },
    [
      memoizedDynamicParams,
      sortingParams,
      router,
      urlSearchParams,
      onSetUrlSearchParamsByTableTab,
    ]
  );

  const onColumnFiltersChange = useCallback(
    (updater: Updater<ColumnFiltersState>) => {
      const updatedColumnFilters = functionalUpdate(
        updater,
        columnFiltersParams
      );

      setColumnFiltersToURLSearchParams(urlSearchParams, updatedColumnFilters);

      pushToHistory(memoizedDynamicParams, urlSearchParams.toString(), router);

      // Update the table tab url search params in the provider
      onSetUrlSearchParamsByTableTab?.();
    },
    [
      memoizedDynamicParams,
      columnFiltersParams,
      router,
      urlSearchParams,
      onSetUrlSearchParamsByTableTab,
    ]
  );

  const onPaginationChange = useCallback(
    (updater: Updater<PaginationState>) => {
      const updatedPagination = functionalUpdate(updater, paginationParams);

      setPaginationToURLSearchParams(urlSearchParams, updatedPagination);

      pushToHistory(memoizedDynamicParams, urlSearchParams.toString(), router);

      // Update the table tab url search params in the provider
      onSetUrlSearchParamsByTableTab?.();
    },
    [
      memoizedDynamicParams,
      paginationParams,
      router,
      urlSearchParams,
      onSetUrlSearchParamsByTableTab,
    ]
  );

  return { onColumnFiltersChange, onPaginationChange, onSortingChange };
};
