import { useMemo } from "react";

import { useRouter } from "next/router";

import { StatementsParsedQueryParams } from "@constants";
import {
  extractDateRangeFromParams,
  extractDateFromParams,
  extractDocumentTypesFromParams,
} from "@utils";

/**
 * This hook is used to get the statements parsed query params
 * @returns The statements parsed query params
 */
export const useGetStatementsParsedQueryParams =
  (): StatementsParsedQueryParams => {
    const { query } = useRouter();

    const getStatementsParsedQueryParams = () => {
      const {
        dateRange: dateRangeParams,
        dateStart: dateStartParams,
        dateEnd: dateEndParams,
        documentTypes: documentTypesParams,
      } = query;

      const dateRange = extractDateRangeFromParams(dateRangeParams);

      const dateStart = extractDateFromParams(dateStartParams);

      const dateEnd = extractDateFromParams(dateEndParams);

      const documentTypes = extractDocumentTypesFromParams(documentTypesParams);

      return {
        dateRange,
        dateStart,
        dateEnd,
        documentTypes,
      };
    };

    const parsedQueryParams = useMemo(getStatementsParsedQueryParams, [query]);

    return parsedQueryParams;
  };
