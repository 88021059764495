import {
  SortingState,
  ColumnFiltersState,
  PaginationState,
  ColumnFilter,
} from "@tanstack/react-table";

import { Params } from "@constants";

/**
 * This function is used to extract the sorting state from the URL
 * @param sortingParams Sorting params from the URL
 * @returns Sorting state
 */
export const extractSortingStateFromParams = (
  sortingParams: Params
): SortingState => {
  // If there are no sorting params, return an empty array
  if (!sortingParams) return [];

  const parseSortingParam = (sortingParam: string) => {
    const [id, order] = sortingParam.split(":");

    // Return format being used by react-table: { id: string, desc: boolean } || undefined
    if (order === "desc" || order === "asc") {
      return { id, desc: order === "desc" };
    }
    return undefined;
  };

  // If using react-table multi-sorting, the sorting params will be an array
  if (Array.isArray(sortingParams)) {
    const sorting: SortingState = [];

    sortingParams.forEach((sortingParam) => {
      const parsedSortingParam = parseSortingParam(sortingParam);

      if (parsedSortingParam) {
        sorting.push(parsedSortingParam);
      }
    });
    return sorting;
  }

  const parsedSortingParam = parseSortingParam(sortingParams);

  return parsedSortingParam ? [parsedSortingParam] : [];
};

/**
 * This function is used to extract the column filters state from the URL
 * @param columnFilterParams Column filter params from the URL
 * @returns Column filters state
 */
export const extractColumnFiltersStateFromParams = (
  columnFilterParams: Params
): ColumnFiltersState => {
  // If there are no column filter params, return an empty array
  if (!columnFilterParams) return [];

  const parseColumnFilterParam = (columnFilterParam: string) => {
    const [id, values] = columnFilterParam.split(":");

    // Return a format being used by react-table: { id: string, value: unknown }
    return { id, value: values.split(",") };
  };

  // If using react-table multi-column filtering, the column filter params will be an array
  if (Array.isArray(columnFilterParams)) {
    return columnFilterParams.map((columnFilterParam) =>
      parseColumnFilterParam(columnFilterParam)
    );
  }

  const parsedColumnFilterParam = parseColumnFilterParam(columnFilterParams);

  return [parsedColumnFilterParam];
};

/**
 * This function is used to set the table sorting state to the URL search params
 * @param searchParams URL search params
 * @param sorting Sorting state
 * @returns void
 */
export const setSortingToURLSearchParams = (
  searchParams: URLSearchParams,
  sorting: SortingState | undefined
) => {
  // Add sorting to the URL only if there is any sorting
  if (sorting && sorting.length > 0) {
    // Generate a string from the sorting state that can be used in the URL
    const sortingQueryString = sorting
      .map(({ id, desc }) => `${id}:${desc ? "desc" : "asc"}`)
      .join("&");

    searchParams.set("sorting", sortingQueryString);
  } else {
    // If there is no sorting, remove the sorting param from the URL
    searchParams.delete("sorting");
  }
};

/**
 * This function is used to generate a column filter query string
 * @param id Column filter id
 * @param value Column filter value
 * @returns Column filter query string
 */
export const generateColumnFiltersQueryString = ({
  id,
  value,
}: ColumnFilter) => {
  // If the value is an empty array, we don't want to add it to the URL
  if (Array.isArray(value) && value.length === 0) return null;

  return `${id}:${Array.isArray(value) ? value.join(",") : value}`;
};

/**
 * This function is used to set the table column filters state to the URL search params
 * @param searchParams URL search params
 * @param columnFilters Column filters state
 * @returns void
 */
export const setColumnFiltersToURLSearchParams = (
  searchParams: URLSearchParams,
  columnFilters: ColumnFiltersState | undefined
) => {
  // Remove existing column filters from the URL to avoid appending to the existing column filters
  searchParams.delete("columnFilters");

  // Add column filters to the URL only if there are any column filters
  if (!columnFilters || columnFilters.length === 0) return;

  columnFilters.forEach((columnFilter) => {
    const columnFiltersQueryString =
      generateColumnFiltersQueryString(columnFilter);

    if (columnFiltersQueryString) {
      searchParams.append("columnFilters", columnFiltersQueryString);
    }
  });
};

/**
 * This function is used to set the table pagination state to the URL search params
 * @param searchParams URL search params
 * @param pagination Pagination state
 * @returns void
 */
export const setPaginationToURLSearchParams = (
  searchParams: URLSearchParams,
  pagination: PaginationState
) => {
  searchParams.set("pageIndex", pagination.pageIndex.toString());
  searchParams.set("pageSize", pagination.pageSize.toString());
};

/**
 * This function is used to merge the column filters state from the URL with the initial column filters state
 * since the backend doesn't return the entire result from an empty array
 * @param initialColumnFilters Initial column filters state
 * @param columnFiltersParsedParams Column filter state
 * @returns Column filters state
 */
export const mergeColumnFilters = (
  initialColumnFilters: ColumnFiltersState,
  columnFiltersParsedParams: ColumnFiltersState
): ColumnFiltersState => {
  const columnFiltersLookup = Object.fromEntries(
    columnFiltersParsedParams.map((filter) => [filter.id, filter])
  );

  return initialColumnFilters.map(
    (columnFilter) => columnFiltersLookup[columnFilter.id] || columnFilter
  );
};

/**
 * This function is used to get the sorting order
 * @param sorting Sorting state [{id: string, desc: boolean}]
 * @returns The sorting order
 */
export const getSortingOrder = (sorting: SortingState) => {
  if (sorting.length === 0) return undefined;

  // We are only using single sorting
  return sorting[0].desc ? "DESC" : "ASC";
};
