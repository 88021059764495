import {
  subYears,
  startOfYear,
  endOfYear,
  isValid,
  parseISO,
  formatISO,
} from "date-fns";

import { DATE_RANGES, DateOptions, Params } from "@constants";

/**
 * This function is used to extract the date range from the URL
 * @param dateRangeParams Date range params from the URL
 * @returns The date range
 */
export const extractDateRangeFromParams = (dateRangeParams: Params) => {
  // Check if the date range params is part of the valid ranges
  if (typeof dateRangeParams === "string") {
    return DATE_RANGES.includes(dateRangeParams) ? dateRangeParams : undefined;
  }

  return undefined;
};

/**
 * This function is used to extract the date from the URL
 * @param dateParams Date params from the URL
 * @returns The date
 */
export const extractDateFromParams = (dateParams: Params) => {
  // Check if the date params is a valid date format
  if (typeof dateParams === "string") {
    return isValid(parseISO(dateParams)) ? dateParams : undefined;
  }

  return undefined;
};

/**
 * This function is used to set the date range options to the URL search params
 * @param searchParams URL search params
 * @param dateOptions Date options (dateRange, dateStart, dateEnd)
 * @returns void
 */
export const setDateRangeOptionsToURLSearchParams = (
  searchParams: URLSearchParams,
  dateOptions: DateOptions
) => {
  const { dateRange } = dateOptions;

  setDateRangeToURLSearchParams(searchParams, dateRange);

  // If the date range is allTime, remove the dates from the URL
  if (dateRange === "allTime") {
    searchParams.delete("dateStart");
    searchParams.delete("dateEnd");
    return;
  }

  if (dateRange === "lastYear") {
    setLastYearDateOptionsToURLSearchParams(searchParams);
    return;
  }

  if (dateRange === "yearToDate") {
    setYearToDateOptionsToURLSearchParams(searchParams);
    return;
  }

  if (dateRange === "custom") {
    setCustomDateOptionsToURLSearchParams(searchParams, dateOptions);
  }
};

/**
 * This function is used to set the date range to the URL search params
 * @param searchParams URL search params
 * @param dateRange Date range
 * @returns void
 */
export const setDateRangeToURLSearchParams = (
  searchParams: URLSearchParams,
  dateRange: string
) => {
  // If dateRange is not empty string and allTime, set it to the URL search params
  if (dateRange && dateRange !== "allTime") {
    searchParams.set("dateRange", dateRange);
    return;
  }
  searchParams.delete("dateRange");
};

/**
 * This function is used to set the last year date options to the URL search params
 * @param searchParams URL search params
 * @returns void
 */
export const setLastYearDateOptionsToURLSearchParams = (
  searchParams: URLSearchParams
) => {
  const currentDate = new Date();

  // Get the start and end date of last year
  const dateStartLastYear = startOfYear(subYears(currentDate, 1));
  const dateEndLastYear = endOfYear(subYears(currentDate, 1));

  // Format the start and end date to this iso format: yyyy-MM-dd
  const formattedDateStart = formatISO(dateStartLastYear, {
    representation: "date",
  });

  const formattedDateEnd = formatISO(dateEndLastYear, {
    representation: "date",
  });

  searchParams.set("dateStart", formattedDateStart);
  searchParams.set("dateEnd", formattedDateEnd);
};

/**
 * This function is used to set year to date options to the URL search params
 * @param searchParams URL search params
 * @returns void
 */
export const setYearToDateOptionsToURLSearchParams = (
  searchParams: URLSearchParams
) => {
  const currentDate = new Date();

  // Get the start date of this year and the end date of today
  const dateStartThisYear = startOfYear(currentDate);
  const dateEnd = currentDate;

  // Format the start and end date to this iso format: yyyy-MM-dd
  const formattedDateStart = formatISO(dateStartThisYear, {
    representation: "date",
  });

  const formattedDateEnd = formatISO(dateEnd, {
    representation: "date",
  });

  searchParams.set("dateStart", formattedDateStart);
  searchParams.set("dateEnd", formattedDateEnd);
};

/**
 * This function is used to set year to date options to the URL search params
 * @param searchParams URL search params
 * @returns void
 */
export const setCustomDateOptionsToURLSearchParams = (
  searchParams: URLSearchParams,
  dateOptions: DateOptions
) => {
  const { dateStart, dateEnd } = dateOptions;

  // Remove existing start and end date from the URL
  searchParams.delete("dateStart");
  searchParams.delete("dateEnd");

  if (dateStart) {
    searchParams.set("dateStart", dateStart);
  }

  if (dateEnd) {
    searchParams.set("dateEnd", dateEnd);
  }
};
